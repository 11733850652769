<template>
  <div class="" style="width: 100%;">
    <v-container>
      <v-row>
        <v-col class='col-md-8 d-flex flex-row align-center'>
          <v-btn text class="mx-2" @click="()=>{this.$router.go(-1)}">
            <v-icon dark>mdi-arrow-left</v-icon>
          </v-btn>
          <h1><span v-if="!this.isNewPO">Edit</span> <span v-else>New </span>{{' '+this.singular}}<span v-if="this.data.firstName">:</span> {{this.data.firstName}} {{this.data.lastName}}</h1>
        </v-col>
        <v-col class="col-md-4">
          <v-progress-circular
            indeterminate
            color="green"
            v-if="loader"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row v-if="!loader">
        <v-col cols="2">
          <v-autocomplete
            v-model="purchaseOrder.supplierId"
            :items="suppliers"
            item-text="name"
            item-value="id"
            outlined
            dense
            :disabled="supplierDisabledCriteria"
            @change="supplierSelected"
            label="Supplier"
          />
          <div v-if="purchaseOrder.supplierId" style="height:900px; overflow-y: scroll;" class="d-flex flex-column">
            <h4>Products from {{this.selectedSupplier.name}}</h4>
            <div v-for="(item,index) in supplierProducts" :key="index">
              <div style="padding: 10px; background-color:rgba(0,0,0,0.09); margin-bottom: 3px; border-radius: 7px;" class="d-flex flex-row align-center justify-space-between">
                <div>
                  <span>{{item.name}}</span>
                  <br>
                  <span><b>ID:</b> {{item.id}}</span>
                  <br>
                  <span><b>Model #:</b> {{item.sku}}</span>
                  <br>
                  <span><b>Cost Price:</b> {{formatPrice(item.cost_price)}}</span>
                </div>
                <v-btn @click="addToPurchaseOrder(item)" fab x-small color="info"><v-icon>mdi-chevron-right</v-icon></v-btn>
              </div>
            </div>
          </div>
        </v-col>
        <v-col class="col-8" style="background-color: rgba(0,0,0,0.05); border-radius: 10px; padding: 20px;">          
          <div class="d-flex flex-column align-space-between justify-space-between" style="height: 800px;">
            <div style="width: 100%;">
              <!-- header -->
              <div class="d-flex flex-row justify-space-between">
                <div class="d-flex flex-column" style="width: 50%; margin-bottom: 10px;">
                  <!-- <img src="" alt=""> -->
                  <h1 class="d-flex flex-row align-center">
                    <div v-if="autosaveStatus==undefined" style="margin-right: 10px; background-color: red; border-radius: 15px; width: 15px; height: 15px;"></div>
                    <div v-if="autosaveStatus=='saved'" style="margin-right: 10px; background-color: green; border-radius: 15px; width: 15px; height: 15px;"></div>
                    <div v-if="autosaveStatus=='unsaved'" style="margin-right: 10px; background-color: orange; border-radius: 15px; width: 15px; height: 15px;"></div>
                    Purchase Order<span style="margin-left: 10px;">#{{purchaseOrder.id}}</span>
                  </h1> 
                </div>
                <div class="d-flex flex-column align-end" style="width: 50%;">
                  <span>{{currentFormattedDate}}</span>
                </div>
              </div>
              <div style="width: 100%; margin-top: 10px;" class="d-flex flex-row justify-space-between">
                <div v-if="purchaseOrder.supplierId" class="d-flex flex-column" style="width:50%;">
                  <span>
                    <b>Supplier</b>
                    <v-btn v-if="!supplierEditing" style="margin-left: 5px;" color="info" x-small @click="setSupplierEditing(true)">Edit</v-btn>
                    <v-btn v-if="supplierEditing" style="margin-left: 5px;" color="success" x-small @click="saveSupplierInfo()">Save</v-btn>
                    <v-btn v-if="supplierEditing " style="margin-left: 5px;" color="info" x-small @click="setSupplierEditing(false)">Cancel</v-btn>
                  </span>
                  <div v-if="supplierEditing">
                    <v-text-field v-if="tempSupplierInfo.name" style="width: 90%;" dense v-model="tempSupplierInfo.name"></v-text-field>
                    <v-text-field v-if="tempSupplierInfo.address" style="width: 90%;" dense v-model="tempSupplierInfo.address"></v-text-field>
                    <v-text-field v-if="tempSupplierInfo.phone" style="width: 90%;" dense v-model="tempSupplierInfo.phone"></v-text-field>
                    <v-text-field v-if="tempSupplierInfo.email" style="width: 90%;" dense v-model="tempSupplierInfo.email"></v-text-field>
                  </div>
                  <div v-else class="d-flex flex-column">
                    <span v-if="purchaseOrder.supplierInfo.name">{{purchaseOrder.supplierInfo.name}}</span>
                    <span v-if="purchaseOrder.supplierInfo.address">{{purchaseOrder.supplierInfo.address}}</span>
                    <span v-if="purchaseOrder.supplierInfo.phone">{{purchaseOrder.supplierInfo.phone}}</span>
                    <span v-if="purchaseOrder.supplierInfo.email">{{purchaseOrder.supplierInfo.email}}</span>
                  </div>
                </div>
                <div v-else class="d-flex flex-column" style="width:50%;">
                  <span><b>Supplier</b></span>
                  <span>-</span>
                </div>
                <div v-if="purchaseOrder.shipToInfo" class="d-flex flex-column" style="width:50%;">
                  <span>
                    <b>Ship To</b>
                    <v-btn v-if="!shipToEditing" style="margin-left: 5px;" color="info" x-small @click="setShipToEditing(true)">Edit</v-btn>
                    <v-btn v-if="shipToEditing" style="margin-left: 5px;" color="success" x-small @click="saveShipToInfo()">Save</v-btn>
                    <v-btn v-if="shipToEditing " style="margin-left: 5px;" color="info" x-small @click="setShipToEditing(false)">Cancel</v-btn>
                  </span>
                  <div v-if="shipToEditing">
                    <v-text-field style="width: 90%;" dense v-model="tempShipToInfo.name"></v-text-field>
                    <v-text-field style="width: 90%;" dense v-model="tempShipToInfo.address"></v-text-field>
                    <v-text-field style="width: 90%;" dense v-model="tempShipToInfo.phone"></v-text-field>
                    <v-text-field style="width: 90%;" dense v-model="tempShipToInfo.email"></v-text-field>
                  </div>
                  <div v-else class="d-flex flex-column">
                    <span>{{purchaseOrder.shipToInfo.name}}</span>
                    <span>{{purchaseOrder.shipToInfo.address}}</span>
                    <span>{{purchaseOrder.shipToInfo.phone}}</span>
                    <span>{{purchaseOrder.shipToInfo.email}}</span>
                  </div>
                </div>
                <div v-else class="d-flex flex-column" style="width:50%;">
                  <span><b>Ship To</b></span>
                  <span>-</span>
                </div>
              </div>
            </div>
            <div style="height: 500px; border: 2px solid rgba(0,0,0,0.15); border-radius: 10px; margin-top: 20px; padding: 20px; overflow-y: scroll;">
              <!-- body -->
                <v-simple-table style="height: 100%; background-color: rgba(0,0,0,0)">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th></th>
                      <th class="text-left">
                        Item No.
                      </th>
                      <th class="text-left">
                        Item Name
                      </th>
                      <th class="text-left">
                        Description
                      </th>
                      <th class="text-left">
                        Unit Price
                      </th>
                      <th class="text-left">
                        Qty
                      </th>
                      <th class="text-right">
                        Line Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in purchaseOrder.purchaseOrderItems" :key="item.id">
                      <td><v-btn icon dark small color="red" @click="removeFromPurchaseOrder(item)"><v-icon >mdi-close</v-icon></v-btn></td>
                      <td>{{item.sku}}</td>
                      <td>{{item.name}}</td>
                      <td>{{item.description}}</td>
                      <td>{{formatPrice(item.price)}}</td>
                      <td>
                        <v-text-field
                          outlined 
                          style="width:50px;" 
                          dense 
                          type="number" 
                          :rules="quantityRules" 
                          v-model="item.quantity"
                          @input="updateTotals()">
                        </v-text-field>
                      </td>
                      <td class="d-flex flex-row justify-end align-center"><span v-if="item.lineTotal">{{formatPrice(item.lineTotal)}}</span><span v-else>-</span></td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
            <div class="d-flex flex-row justify-end" style="width: 100%; margin-top: 10px;">
              <div class="d-flex flex-column align-end">
                <b>Subtotal</b>
                <b>
                  <span>
                    <v-btn style="margin-left: 5px;" color="warning" x-small @click="()=>{this.$router.push({path: '/taxes/view/'})}">Edit</v-btn>
                  </span> 
                  Tax ({{this.taxRate}}%)</b>
                <b>Total</b>
              </div>
              <div style="width: 120px; padding-right:20px;" class="d-flex flex-column align-end">
                <span class="d-flex flex-column align-start">
                  <div>{{formatPrice(purchaseOrder.subtotal)}}</div>
                  <div>{{formatPrice(purchaseOrder.subtotal*(taxRate/100)||0)}}</div>
                  <div>{{formatPrice(purchaseOrder.grandTotal)}}</div>
                </span>
              </div>
            </div>
            <div style="margin-top: 20px; height: 100px;">
              <!-- footer -->
              <v-btn style="margin-right:5px;" color="error" @click="deleteDraftA()" :disabled="purchaseOrder.status!==0">Delete Draft</v-btn>
              <v-btn @click="signA()" color="success" :disabled="!supplierDisabledCriteria">Sign Purchase Order</v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
      </v-row>
    </v-container>
    <v-dialog
      v-model="deleteDialog"
      max-width="490"
    >
      <v-card>
        <v-card-title class="text-h5">
          Delete this Purchase Order Draft?
        </v-card-title>
        <v-card-text>You will not be able to recover it once deleted.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="deleteDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="error"
            @click="deleteDraftB()"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="signDialog"
      max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Are you sure you wish to sign this Purchase order?
        </v-card-title>
        <v-card-text>Please ensure all fields are correct. Your identity will be linked to this document.</v-card-text>
        <v-card-text>Enter your password to confirm.</v-card-text>
        <v-text-field label="Password" placeholdler="Password" type="password" outlined style="width: 300px; margin: 20px;" v-model="userPassword"></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="signDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="error"
            :disabled="!userPassword"
            @click="signB()"
          >
            Confirm Signature
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import moment from 'moment';
  import _ from 'lodash'
  import {mapGetters} from 'vuex'
  export default {
    data () {
      return {
        loader: false,

        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        max25chars: v => v.length <= 25 || 'Input too long!',
        autosaveStatus: undefined,
        quantityRules: [
          v => v>=1 || 'Must be more than or equal 1'
        ],

        data: {},
        userTypes: [],
        products: [],
        singular: "Purchase Order",
        singularLower: "purchaseorder",
        plural: "Purchase Orders",
        pluralLower: "purchaseorders",
        suppliers: [],
        supplierProducts: [],
        purchaseOrder: {
          purchaseOrderItems: [], 
          subtotal: 0, 
          grandTotal: 0, 
          supplierInfo: {}, 
          shipToInfo: {
            name: "Teelucksingh's Furniture Limited",
            address: "57 High Street, Princes Town",
            phone: '3332123',
            email: 'teelucksinghs@gmail.com',
        }},
        valid: false,
        taxRate: 10,
        created: false,
        isNewPO: "",
        supplierEditing: false,
        shipToEditing: false,
        tempSupplierInfo: {},
        tempShipToInfo: {},
        renderKey: 0,
        deleteDialog: false,
        signDialog: false,
        userPassword: null,
      }
    },
    watch: {
      purchaseOrder: {
        handler(){
          this.draftWasSaved = true
          this.updateTotals()
          this.debouncedUpdatePurchaseOrder()
        },
        deep: true
      }
    },
    created(){
      this.debouncedUpdatePurchaseOrder = _.debounce(this.autosave, 500)
    },
    async mounted(){
      try{
        this.loader = true;
        if(this.$route.params.id){
          let po = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`);
          if(po.data.error) throw po.data.error
          this.purchaseOrder = po.data.data
          if(this.purchaseOrder.status > 0) await this.$router.push({path: `/purchaseorders/view/${this.$route.params.id}`})
          let suppliers = await axios.get(`${this.getEndpoint}/api/suppliers`);
          if(suppliers.data.error) throw suppliers.data.error
          this.suppliers = suppliers.data.data
          if(this.purchaseOrder.supplierId){            
            let products = await axios.get(`${this.getEndpoint}/api/products/bySupplierNoVariables/${this.purchaseOrder.supplierId}`);
            if(products.data.error) throw products.data.error
            this.supplierProducts = products.data.data
            this.supplierProducts.forEach(x=>{
              x.quantity=1
              if(this.purchaseOrder.purchaseOrderItems)
                this.purchaseOrder.purchaseOrderItems.filter(y=>y.id==x.id)[0] != undefined ? x.addToPurchaseOrder = true : x.addToPurchaseOrder = false
            })
            this.purchaseOrder.subtotal = this.subtotal
            this.updateTotals()
          }
        }
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error");
      }
      finally {
        this.loader = false;
      }
    },
    computed: {
      supplierDisabledCriteria(){
        return this.purchaseOrder.purchaseOrderItems.length>0
      },
      itemDisabledCriteria(item){
        return item.addToPurchaseOrder
      },
      selectedSupplier(){
        if(this.suppliers.length>0){
          return this.suppliers.find(x=>x.id==this.purchaseOrder.supplierId)
        }
        return "-"
      },
      currentFormattedDate(){
        return moment().format("DD/MM/YYYY")
      },
      ...mapGetters(['getEndpoint'])
    },
    methods: {
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      signA(){
        if(this.supplierEditing){
          this.snackText = "You are currently editing the supplier info. Please Cancel or Save your changes."
          this.snackBar = true;
        }
        if(this.shipToEditing){
          this.snackText = "You are currently editing the Ship To information. Please Cancel or Save your changes."
          this.snackBar = true;
        }
        for(let item of this.purchaseOrder.purchaseOrderItems){
          if(item.quantity<=0){
            this.snackText = "Your quantity is invalid for "+item.name;
            this.snackBar = true;
            return;
          }
        }
        if(!this.supplierEditing && ! this.shipToEditing) 
          this.signDialog = true;
      },
      async signB(){
        try {
          this.loader = true
          if(this.purchaseOrder.status > 0) throw "Cannot Re-Sign Purchase Order"
          if(!this.userPassword) throw "Password Needed to verify the signature."
          let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}/sign/${this.purchaseOrder.id}`, {password: this.userPassword})
          if(res.data.error) throw res.data.error
          this.snack("Signed!")
          await this.$router.push({path: `/purchaseorders/view/${this.$route.params.id}`})
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        }finally{
          this.loader = false
        }
      },
      async addToPurchaseOrder(item){
        try {
          if(item.addToPurchaseOrder){
            this.snackText = item.name+" is already added.";
            this.snackBar = true;
            return;
          }
          this.purchaseOrder.status = 0

          let newItem = {
            id: item.id,
            sku: item.sku,
            name: item.name,
            type: item.type,
            price: item.regular_price,
            quantity: item.quantity,
            lineTotal: item.lineTotal
          }
          if(!this.purchaseOrder.purchaseOrderItems) this.purchaseOrder.purchaseOrderItems = []
          this.purchaseOrder.purchaseOrderItems.push(newItem)
          this.supplierProducts.filter(x=>x.id==item.id)[0].addToPurchaseOrder = true
          this.updateTotals()
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        }
      },
      async removeFromPurchaseOrder(item){
        let y = this.supplierProducts.filter(x=>x.id==item.id)[0]
        y.addToPurchaseOrder = false
        y.quantity = 1
        this.purchaseOrder.purchaseOrderItems = this.purchaseOrder.purchaseOrderItems.filter(x=>x.id!=item.id)
        this.updateTotals()
      },
      updateTotals(){
        if(!this.purchaseOrder.purchaseOrderItems) return
        this.purchaseOrder.purchaseOrderItems.forEach(item=>item.lineTotal = item.quantity*item.price)
        this.purchaseOrder.subtotal = this.purchaseOrder.purchaseOrderItems.reduce((a,x)=>a+x.lineTotal,0)
        this.purchaseOrder.grandTotal = ((this.taxRate/100)*this.purchaseOrder.subtotal) + this.purchaseOrder.subtotal
        this.$forceUpdate()
      },
      async supplierSelected(){
        try {
          if(this.purchaseOrder.supplierId == null) throw 'Cleared the supplier selection.'
          let products = await axios.get(`${this.getEndpoint}/api/products/bySupplierNoVariables/${this.purchaseOrder.supplierId}`);
          if(products.data.error) throw products.data.error
          this.supplierProducts = products.data.data
          this.supplierProducts.forEach(x=>{x.quantity=1})          
          this.purchaseOrder.supplierInfo = this.suppliers.find(x=>x.id==this.purchaseOrder.supplierId)
        } catch (error) { 
          console.error(error)
          this.snack(error.msg || error, "error");
        }
      },
      setSupplierEditing(bool){
        if(bool){
          if(this.isNewPO) this.purchaseOrder.status = undefined
          else this.purchaseOrder.status = 0
          this.tempSupplierInfo = {...this.purchaseOrder.supplierInfo}
        }else{
          if(this.isNewPO) this.purchaseOrder.status = undefined
          else{
            if(this.shipToEditing){
              this.purchaseOrder.status = 0
            }else{
              this.purchaseOrder.status = 0
            }
          }
          this.tempSupplierInfo = {}
        }
        this.supplierEditing = bool
      },
      async saveSupplierInfo(){
        try {
          this.purchaseOrder.supplierInfo = {...this.tempSupplierInfo}
            let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/updateDraft/${this.purchaseOrder.id}`, this.purchaseOrder)
            if(res.status==200){
              this.setSupplierEditing(false)
          }
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        }
      },
      setShipToEditing(bool){
        if(bool){
          if(this.isNewPO) this.purchaseOrder.status = undefined
          else this.purchaseOrder.status = 0
          this.tempShipToInfo = {...this.purchaseOrder.shipToInfo}
        }else{
          if(this.isNewPO) this.purchaseOrder.status = undefined
          else {
            if(this.supplierEditing){
              this.purchaseOrder.status = 0
            }else{
              this.purchaseOrder.status = 'draftSaved'
            }
          }
          this.tempShipToInfo = {}
        }
        this.shipToEditing = bool
      },
      async saveShipToInfo(){
        try {
          this.purchaseOrder.shipToInfo = {...this.tempShipToInfo}
            let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/updateDraft/${this.purchaseOrder.id}`, this.purchaseOrder)
            if(res.data.error) throw res.data.error
            this.setShipToEditing(false)
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        }
      },
      async autosave(){
        try {
          this.autosaveStatus = "unsaved"
          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/updateDraft/${this.purchaseOrder.id}`, this.purchaseOrder)
          if(res.data.error) throw res.data.error
          this.autosaveStatus = "saved"
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
          this.autosaveStatus = undefined
        }
      },
      deleteDraftA(){
        this.deleteDialog = true
      },
      async deleteDraftB(){
        try {
          this.deleteDialog = false
          let res = await axios.delete(`${this.getEndpoint}/api/${this.pluralLower}/deleteDraft/${this.purchaseOrder.id}`)
          if(res.data.error) throw res.data.error
          await this.$router.push({path: "/purchasing"})
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        }
      },
      formatPrice(value){
        if(!value) return "";
        let formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        });
        return formatter.format(parseFloat(value));
      },
    }
  }
</script>
